import openDialog from "./openDialog";
/**
 * Opens a dialog with the given arguments. Producing a dialog with title and description and a list of actions.
 * @param {Object} data ContinueToArguments
 * @param {String} data.Title Title
 * @param {String} data.Description Description
 * @param {Array} data.Actions Actions
 * @param {String} data.Actions[].Title Title
 * @param {String} data.Actions[].Action Action
 */
export default function (data) {
    openDialog({ Title: data.Title, Template: "ContinueTo", Data: data });
}
